import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Page from '../components/layouts/Page'
import TextLink from '../components/parts/TextLink'
import Heading from '../components/parts/Heading'
import Paragraph from '../components/parts/Paragraph'
import Mark, { markImages } from '../components/parts/Mark'

import Collapsible from '../components/parts/Collapsible'
import FAQSidebar from '../components/parts/FAQSidebar'
import theme from '../config/theme'

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query FaqQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//data/faq//" } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          html
          frontmatter {
            title
            front
            order
            category
          }
        }
      }
    }
  `)
  const {
    allMarkdownRemark: { nodes: entries },
  } = data
  const allFaq = {}

  entries.forEach((item) => {
    if (!allFaq[item.frontmatter.category]) {
      allFaq[item.frontmatter.category] = []
    }
    allFaq[item.frontmatter.category].push(item)
  })

  const [active, setActive] = useState('Abo')

  const categories = ['Abo', 'Kinderwagen', 'Kinderfahrrad', 'Versand', 'Sonstiges']

  const descriptionStyle = {
    marginTop: '1rem',
    maxWidth: '30rem',
    lineHeight: '1.5',
    letterSpacing: '0.4px',
  }

  return (
    <Page
      title="FAQ"
      description=""
      titleNode={(
        <div>
          <Heading level={1} style={{ color: theme.palette.red[0] }}>
            Noch{' '}
            <Mark
              image={markImages.yellowNormal}
              style={{
                backgroundPosition: `0px calc(100% + 1.25rem)`,
                color: theme.palette.red[0],
              }}
            >
              Fragen?
            </Mark>
            <span style={{ color: theme.palette.black[0], display: 'block' }}>
              Hier findest du Antworten
            </span>
          </Heading>
          <Paragraph style={descriptionStyle}>
            Falls du hier nicht die Antwort auf deine Frage findest, schreib uns gerne unter{' '}
            <TextLink href="mailto:abo@strollme.de" aria-label="StrollMe Mail">
              abo@strollme.de
            </TextLink>{' '}
            oder nutze die Chatfunktion auf dieser Seite. Wir helfen dir gerne!
          </Paragraph>
        </div>
      )}
      backgroundColor={theme.palette.sand[0]}
      leftColumn={<FAQSidebar categories={categories} onSelect={setActive} active={active} />}
    >
      {entries
        .filter((entry) => entry.frontmatter.category === active)
        .map((filteredEntry) => (
          <Collapsible
            key={filteredEntry.frontmatter.title}
            title={filteredEntry.frontmatter.title}
            htmlContent={filteredEntry.html}
          />
        ))}
    </Page>
  )
}

export default FAQ
