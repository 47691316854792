import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

export const Accordion = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;
  font-family: ${theme('fonts.munkenSans')};
  border-bottom: 3px solid black;
  transition: all 0.5s ease-out;

  ${theme('media.mdUp')} {
    padding-bottom: 0.5rem;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.p`
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  cursor: pointer;

  ${theme('media.mdUp')} {
    font-size: 1.5rem;
    line-height: 2.5;
    letter-spacing: 0.6px;
  }
`

export const Button = styled(Title).attrs({
  as: 'button',
})`
  width: 2rem;
  color: ${palette('black')};
  font-size: 2rem;
  line-height: 1.88;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Content = styled.section`
  width: 90%;
  overflow: hidden;
  line-height: 1.5;
  transition: max-height 0.2s cubic-bezier(1, 0, 0, 1);

  & p {
    padding-bottom: 1rem;
  }
`
