import React from 'react'
import PropTypes from 'prop-types'
import SidebarItems from './styled'
import IconArrow from '../../../static/images/icons/arrow.svg'

const FAQSidebar = ({ categories, onSelect, active }) => (
  <>
    {categories.map((category) => (
      <SidebarItems
        key={category}
        onClick={() => onSelect(category)}
        className={active === category && 'active'}
      >
        {category}
        <IconArrow />
      </SidebarItems>
    ))}
  </>
)

FAQSidebar.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  active: PropTypes.string,
}

FAQSidebar.defaultProps = {
  categories: [{ onSelect: () => {} }],
  onSelect: () => {},
  active: null,
}

export default FAQSidebar
