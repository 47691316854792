import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

const SidebarItems = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
  padding: 0;
  color: ${palette('black')};
  font-weight: 700;
  font-size: 1rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.38;
  text-align: left;
  background-color: ${palette('transparent')};
  border: none;
  border-bottom: 1px solid ${palette('black')};
  outline: none;
  cursor: pointer;

  & svg > g {
    display: none;
  }

  &:hover,
  &:focus,
  &.active {
    color: ${palette('red')};
    border-bottom: 1px solid ${palette('red')};

    & svg g {
      ${theme('media.smUp')} {
        display: unset;
      }
      & use {
        ${theme('media.smUp')} {
          fill: ${palette('red')};

          & + g {
            display: none;
          }
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 5rem;
  }
`

export default SidebarItems
