import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Hashes from 'jshashes'
import {
  Accordion, Title, Button, TitleWrapper, Content,
} from './styled'

const MD5 = new Hashes.MD5()

const Collapsible = ({ title, htmlContent }) => {
  const [open, setOpen] = useState(false)

  const collpasableAnimatio = {
    maxHeight: open ? '50rem' : '0',
  }

  return (
    <Accordion>
      <TitleWrapper>
        <Title onClick={() => setOpen(!open)}>{title}</Title>
        <Button
          onClick={() => setOpen(!open)}
          id={MD5.hex(`collapsible_header_${title}`)}
          aria-controls={MD5.hex(`collapsible_panel_${title}`)}
        >
          {!open ? '+' : '-'}
        </Button>
      </TitleWrapper>
      <Content
        id={MD5.hex(`collapsible_panel_${title}`)}
        aria-labelledby={MD5.hex(`collapsible_header_${title}`)}
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
        style={collpasableAnimatio}
      />
    </Accordion>
  )
}

Collapsible.displayName = 'Collapsible'

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  htmlContent: PropTypes.string.isRequired,
}

export default Collapsible

export const query = graphql`
  fragment FAQEntry on MarkdownRemark {
    html
    frontmatter {
      title
      front
      order
      category
    }
  }
`
